.loader {
    width: 120px;
    height: 120px;
    display: inline-block;
    position: relative;
}
.loader::after,
.loader::before {
    content: '';  
    box-sizing: border-box;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 9px solid #AFBBF8;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 1.5s linear infinite;
}
.loader::after {
    animation-delay: 0.75s; /* Adjusted delay */
    opacity: 0; /* Initial opacity set to 0 */
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}